<template>
  <div class="d-flex msgContent">
    <div
      class="d-flex msgLabel"
      v-for="(item, i) in dataList"
      :key="i"
      :class="'w' + item.width"
    >
      <div class="msgName">
        {{ item.name }}
      </div>
      <div
        class="msgVal flexList"
        v-if="item.zddm[0] != 'bkkm'"
        :title="infoForm[item.zddm]"
      ><div class="line1">{{ infoForm[item.zddm[0]] || ""
        }}{{ infoForm[item.zddm[1]] ? "(" + infoForm[item.zddm[1]] + ")" : "" }}</div>

      </div>
      <div
        class="msgVal flexList line1"
        v-if="item.zddm[0] == 'bkkm'"
        :title="infoForm[item.zddm]"
      >
        <span class="mr-3" v-for="(val, i) in bmkmList" :key="i"
          >{{ i + 1 + "." + val.kmmc }}{{ "(" + val.kmdm + ")" }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getbmkskm, getUserExamFree } from "@/api/examination.js";
export default {
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    form: Object,
  },
  watch: {
    form: {
      immediate: true,
      handler(val) {
        if (val) {
          this.infoForm = JSON.parse(JSON.stringify(val));
          if (this.firstFlag) {
            if (this.infoForm.kmxzfs == 1) {
              this.firstFlag = false;
              this.getKM();
              if (val.bkjbm == "01" || val.bkjbm == "03") {
                this.dataList = this.MKList;
              } else if (val.bkjbm == "02") {
                this.dataList = this.QKList;
              }
            } else {
              this.firstFlag = false;
              this.dataList = this.ZWList;
            }
          }
        }
      },
    },
  },
  data() {
    return {
      firstFlag: true,
      infoForm: {},
      bmkmList: [],
      QKList: [
        {
          name: "报考级别",
          zddm: ["bkjbmc", "bkjbm"],
          width: "50",
        },
        {
          name: "报考专业",
          zddm: ["bkdwzy", "bkdwzym"],
          width: "50",
        },
        {
          name: "报考科目",
          zddm: ["bkkm", "kmdm"],
          width: "100",
        },
        {
          name: "报名点选择",
          zddm: ["kqmc", "kqdm"],
          width: "100",
        },
        {
          name: "报考条件",
          zddm: ["bktj", "bktjm"],
          width: "100",
        },
      ],
      MKList: [
        {
          name: "报考级别",
          zddm: ["bkjbmc", "bkjbm"],
          width: "50",
        },
        {
          name: "报考专业",
          zddm: ["bkdwzy", "bkdwzym"],
          width: "50",
        },
        {
          name: "报考科目",
          zddm: ["bkkm", "kmdm"],
          width: "100",
        },
        {
          name: "报名点选择",
          zddm: ["kqmc", "kqdm"],
          width: "100",
        },
      ],
      ZWList: [
        {
          name: "报考单位",
          zddm: ["bkdwzy", "bkdwzym"],
          width: "50",
        },
        {
          name: "报考职位",
          zddm: ["bkgwxk", "bkgwxkm"],
          width: "50",
        },
      ],
      dataList: [],
    };
  },
  methods: {
    getKM() {
      getbmkskm(this.form.bmh).then((res) => {
        if (res.status) {
          this.bmkmList = res.data;
          this.infoForm["bkkm"] = "";
          this.bmkmList.forEach((v, i) => {
            this.infoForm["bkkm"] += i + 1 + "." + v.kmmc;
          });
        }
      });
    },
  },
};
</script>

<style>
.msgName {
  display: flex;
  align-items: center;
  justify-content: center;
}
.msgVal {
  display: flex;
  /* vertical-align: middle; */
  /* white-space: nowrap; */
}
.w50 {
  width: 50%;
}

.w100 {
  width: 100%;
  height: auto;
}

.w100 > div {
  overflow: auto;
  white-space: normal;
}
</style>
