<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";

@import "~@/assets/css/enroll-form.css";
</style>
<style scoped>
.w20 {
  width: 20%;
}
/* .w20:last-child {
  width: 19%;
} */
.w33 {
  width: 33.33%;
}
/* .w33:last-child {
  width: 34%;
} */
.w50 {
  width: 50%;
}

.w66 {
  width: 66.66%;
}

.w100 {
  width: 100%;
  height: unset !important;
  /* min-height: 45px !important; */
}
.w100 > div {
  overflow: auto;
  white-space: normal;
  /* line-height: unset !important; */
}

.form-select {
  padding: 3px;
}

.msgContent {
  /* border: unset; */
}
.enrollForm {
  border: none;
}

.msgLabel {
  /* border: 1px solid #dfdfdf; */
}
.msgLabel.msgtextarea .msgVal {
  display: flex !important;
}

.msgVal {
  padding: 0 10px;
}
.msgName {
  display: flex;
  align-items: center;
  justify-content: center;
}
.msgVal {
  display: flex;
  /* vertical-align: middle; */
  /* white-space: nowrap; */
}
.upload-demo {
  display: flex;
  align-items: center;
}
/deep/.el-select.blueBorder .el-input__inner {
  border-color: rgb(23, 118, 210);
}

.guding-img {
  width: 33.33%;
  min-height: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
}
</style>
<style type="text/css" scoped>
/deep/ .selectShow [readOnly] {
  border: none;
}
.selectShow :disabled {
  border: none;
}
.custom-file-label::after {
  display: none;
}
.el-form-item {
  margin-bottom: unset;
  width: 100%;
}

.msgtextarea .msgVal {
  display: block;
}

/deep/.el-input__inner {
  background-color: #fff9eb;
  width: 100%;
  height: 30px;
  background: unset;
}

/deep/ .el-textarea__inner {
  background-color: #fff9eb;
}

/deep/.el-form-item__content {
  line-height: inherit;
}

.el-form-item--small .el-form-item__error {
  margin-top: 2px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

/deep/.el-form-item__error {
  position: absolute !important;
  top: 20%;
  left: 60%;
  text-align: center;
  width: 120px;
  height: 21px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 21px;
  z-index: 99;
}
/deep/ .el-input__icon {
  line-height: 30px;
}

.el-radio {
  margin-bottom: 0;
}
.custom-file-label::after {
  display: none;
}
.msgLabel {
  /*height: 45px;*/
}
.personPic {
  height: 270px;
}
.pay-box img {
  width: 56px;
  height: 56px;
}

.flex-5 {
  flex: 5;
}
.flex-7 {
  flex: 7;
}
.msgVal,
.msgName {
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  /*line-height: 15px;*/
}
.canEdit {
  border: 1px solid red;
  margin: 1px;
}
</style>
<script>
import draggable from "vuedraggable";
import YzSelect from "@/components/form/yzSelect.vue";
import YzRadio from "@/components/form/yzRadio.vue";
import YzSwitch from "@/components/form/yzSwitch.vue";
import yzFormItem from "@/components/form/yzFormItem.vue";

import YzSelectMulit from "@/components/form/yzSelectMulit.vue";
import $ from "jquery";
import {
  getExamForm,
  getUnit,
  getPosition,
  getSubject,
  getExamInfo,
} from "@/api/examination.js";
import { getUserEdu } from "@/api/userInfo.js";
import { checkFile, unitsChange, verifyPhone } from "@/libs/common";
import { covertToForm } from "@/api/common/draggable.js";
import { uploadFiles, getUploadLimit } from "@/api/index";
/**
 * 报名信息提交
 */
export default {
  components: {
    draggable,
    YzSelect,
    YzSelectMulit,
    YzRadio,
    YzSwitch,
    yzFormItem,
  },
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    ksbmbh: {
      type: String,
    },
    bmh: {
      type: String,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    form: Object,
    examInfo: Object,
    kxgList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    errorList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    specialData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isShowYJ:{
      type:Boolean,
      require:false,
      default:false
    }
  },
  data() {
    return {
      headFlag: true,
      eduList: [],
      xkList: [],
      dwList: [],
      list1: [],
      list2: [],
      list3: [],
      img: "",
      addList: [
        {
          sfbt: true,
          zddm: "xm",
          zdmc: "姓名",
          data: "",
          zdfl: "1",
          txlx: "input",
          zdbl: "50",
          readOnly: true,
          json: "xm",
        },
        {
          sfbt: true,
          glzdb: false,
          zddm: "xbm",
          zdmc: "性别",
          zdbmc: "dict_xb",
          zdkxz: "1|2,男|女",
          zdcd: 2,
          zdfl: "1",
          txlx: "radio",
          zdbl: 50,
          readOnly: true,
          json: "xbm,xb",
        },
        {
          zddm: "sfzjlxm",
          zdbmc: "dict_sfzjlx",
          glzdb: true,
          zdcd: 30,
          zdfl: "1",
          sfbt: true,
          zdmc: "证件类型",
          txlx: "select",
          zdbl: 50,
          readOnly: true,
          json: "sfzjlxm,sfzjlxmc",
        },
        {
          sfbt: true,
          zddm: "sfzjh",
          zdmc: "证件号码",
          data: "",
          zdfl: "1",
          txlx: "input",
          zdbl: "50",
          readOnly: true,
          json: "sfzjh",
        },

        {
          zddm: "csrq",
          zdbmc: "",
          glzdb: false,
          zdcd: 10,
          zdfl: "1",
          sfbt: true,
          zdmc: "出生年月",
          txlx: "date",
          zdbl: 50,
          json: "csrq",
        },
        {
          glzdb: true,
          sfbt: true,
          txlx: "select",
          zdbl: 50,
          zdcd: 30,
          zdbmc: "dict_mz",
          zddm: "mzm",
          zdfl: "1",
          zdmc: "民族",
          json: "mzm,mzmc",
        },
      ],
      astrict: {
        gznxzm: {},
      },
      addxk: [
        {
          sfbt: true,
          zddm: "sfty",
          zdmc: "告知承诺制",
          zdfl: "1",
          data: [],
          txlx: "gzcn",
          zdbl: "100",
          // readOnly: true,
        },
        {
          sfbt: true,
          zddm: "bkgwxk",
          json: "bkgwxk",
          zdmc: "报考专业",
          data: [],
          zdfl: "1",
          txlx: "input",
          zdbl: "100",
          readOnly: true,
        },
      ],
      adddw: [
        {
          sfbt: true,
          zddm: "bkdwzym",
          json: "bkdwzym,bkdwzy",
          zdmc: "报考单位",
          data: [],
          zdfl: "1",
          txlx: "select",
          zdbl: "50",
          readOnly: true,
        },
        {
          sfbt: true,
          zddm: "bkgwxkm",
          json: "bkgwxkm,bkgwxk",
          zdmc: "报考岗位",
          data: [],
          zdfl: "1",
          txlx: "select",
          zdbl: "50",
          readOnly: true,
        },
      ],
      xlDict: {
        zgxlm: "xlm", //最高学历码
        zgxlmc: "xlmc", //最高学历名称
        // zgxwm: "hdxwmc", //最高学位码
        // zgxwmc: "hdxwm", //最高学位名称
        zhbyxxmc: "byyxxhdw", //最后毕业学校
        zhbyzy: "sxzymc", //最后毕业专业
        zgxlxxfs: "xxfsm", //最高学历学习方式
        zgxlzfh: "xlzsh", //最高学历证书号
        // zgxwxxfs: "",//最高学位学习方式
        // zgxwzsh: "xwzsh", //最高学位证书号
        zhbysj: "jsxyny", //最后毕业时间
        // zhbyxxlb: "",//最后毕业学校类别
        zhbyxxdqm: "gjdqm", //最后毕业学校国家地区码
        zhbyxxdq: "gjdqmc", //最后毕业学校国家地区
        xlshzt: "xlshzt", //学历审核状态
        // xlshsm: "xlshbz", //学历审核状态说明
        zgxlsid: "sid",
        xlzm: "xlzm",
      },
      dialogVisible: false,
      zwList: [],
    };
  },
  methods: {
    open() {
      if (this.inspect()) {
        this.dialogVisible = true;
        return;
      }
    },
    save() {
      this.$emit("save");
    },
    submitFrom() {
      this.$emit("submit");
    },

    //检验报名表单
    inspect() {
      let isSubmit = true;
      let data = covertToForm(this.list1);
      for (let index = 0; index < data.length; index++) {
        if (data[index].must) {
          if (
            typeof data[index].bound != "string" &&
            (this.form[data[index].bound[0]] == null ||
              this.form[data[index].bound[0]].length == 0)
          ) {
            isSubmit = false;
          } else if (
            typeof data[index].bound == "string" &&
            (this.form[data[index].bound] == null ||
              this.form[data[index].bound].length == 0)
          ) {
            isSubmit = false;
          }
        }
        if (!isSubmit) {
          this.$alert(data[index].name + "不能为空", "提示", {
            confirmButtonText: "确定",
          });
          return false;
        }
        if (data[index].bound == "yddh" && this.form.zgshzt == 0) {
          let result = verifyPhone(this.form.yddh);
          if (!result.isRight) {
            this.$alert(result.errorMess, "提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        }
      }
      if (
        !this.form.sfty &&
        !this.form.gznxzm &&
        this.form.sfty !== null &&
        this.examInfo.sfgzcn
      ) {
        this.$alert("不采用告知承诺制，必须上传工作年限证明", "提示", {
          confirmButtonText: "确定",
        });
        return false;
      }

      if (isSubmit) {
        return true;
      }
    },
    //获取考试详情
    // getExamInfo() {
    //   getExamInfo(this.ksbmbh).then((res) => {
    //     if (res.status) {
    //       this.examInfo = res.data;
    //       this.getFormList();
    //     }
    //   });
    // },
    //获取选科
    getXKList() {
      return new Promise((re) => {
        getSubject({ flbm: this.examInfo.flbm }).then((res) => {
          if (res.status) {
            let xkList = [];
            let data = res.data;
            data.forEach((item, index) => {
              xkList.push({
                name: item.xkmc,
                value: item.xkdm,
                detail: item,
              });
            });
            this.xkList = xkList;
            re(xkList);
          }
        });
      });
    },
    //获取岗位
    getZwList() {
      return new Promise((re) => {
        getPosition(this.ksbmbh).then((res) => {
          let data = res.data;
          this.zwList = [];
          this.dwList = [];
          let dwobj = {};
          data.forEach((item, index) => {
            item.name = item.zwmc + "(" + item.zwbm + ")";
            item.value = item.zwbm;
            if (dwobj[item.bkdwdm]) {
              dwobj[item.bkdwdm].zwlist.push(item);
            } else {
              dwobj[item.bkdwdm] = {
                name: item.bkdw,
                value: item.bkdwdm,
                zwlist: [item],
              };
            }
          });
          this.dwList = Object.values(dwobj);
          let dw_ = this.dwList.filter((v) => {
            return v.value == this.form.bkdwzym;
          });
          this.zwList = this.dwList.filter((v) => {
            return v.value == this.form.bkdwzym;
          })[0].zwlist;
          this.list1[1].data.push(...this.zwList);
          this.list1[0].data.push(...this.dwList);
          re();
        });
      });
    },
    listDiff() {
      this.list2 = [];
      this.list3 = [];
      //判断可修改字段
      if (this.kxgList) {
        this.list1.forEach((item, index) => {
          //特殊名单中的信息不能修改
          if (this.specialData.json) {
            let obj = JSON.parse(this.specialData.json) || "";
            if (obj && obj[item.zddm]) {
              item.readOnly = true;
              if (item.glzdb || item.zdkxz) {
                item.txlx = "input";
                item.zddm = item.xszd;
              }
            }
          }

          //学历相关的字段除了选择学历外不能修改
          if (this.xlDict[item.zddm] && item.zddm != "zgxlm") {
            item.readOnly = true;
          }
          item.error = false;
          if (
            this.kxgList.filter((v) => {
              return v == item.zddm && !this.xlDict[v];
            })[0]
          ) {
            item.canEdit = true;
          } else if (
            this.kxgList.filter((v) => {
              return v == item.zddm && this.xlDict[v];
            })[0]
          ) {
            for (const val of this.list1) {
              if (val.zddm == "zgxlm") {
                val.canEdit = true;
              }
            }
          }
          //错误字段标红
          if (
            this.errorList.filter((v) => {
              return v == item.zddm;
            })[0]
          ) {
            item.error = true;
          }
          if (this.examInfo.zpysyq != 4) {
            if (item.zdfl == "1") {
              this.list2.push(item);
            } else {
              this.list3.push(item);
            }
          } else {
            this.list3.push(item);
          }
        });
        // 学历错误字段
        this.kxgList.forEach((v) => {
          if (this.xlDict[v]) {
            $("." + v).addClass("canEdit");
            $(".xlbtn").removeClass("xlbtn");
          }
        });
      }
      if (this.examInfo.zpysyq != 4) {
        let row = 0; // 0：左，1：右
        this.list3.forEach((v) => {
          if (v.zdbl == 100) {
            row = 0;
            v["row"] = "w-100";
            return;
          }
          if (row == 0) {
            v["row"] = "col-5";
            row = 1;
          } else {
            v["row"] = "col-7";
            row = 0;
          }
        });
      }
    },
    //获取文件限制
    getLimit() {
      getUploadLimit({ module: "WorkCertificate" }).then((res) => {
        if (res.status) {
          this.astrict.gznxzm["ext"] =
            "." + res.data.extLimit.replaceAll(",", ",.");
          this.astrict.gznxzm["size"] = res.data.sizeLimit;
          let size = unitsChange(res.data.sizeLimit);
          this.astrict.gznxzm["text"] =
            "只支持" + res.data.extLimit + "，类型的文件，且不超过" + size;
        }
      });
    },
    //上传文件
    uploadFile(file, key) {
      let _this = this;
      let fileRule = "";
      file = file.raw;
      let result = checkFile(file, this.astrict[key]);

      if (result.status) {
        if (key == "gznxzm") {
          fileRule = "WorkCertificate";
        }
        uploadFiles(fileRule, file, _this.examInfo.ksbmbh).then((res) => {
          if (res.status) {
            let resFile = res.data[0];
            _this.$message({ type: "success", message: "上传成功" });
            _this.form[key] = resFile.fileId;
            this.$emit("input", this.form);
          }
        });
      } else {
        _this.$message({ type: "error", message: result.message });
      }
      this.$refs[key].clearFiles();
      return false;
    },
    //获取考试表单列表
    getFormList() {
      this.list1 = [];
      if (this.examInfo.kslxmc != "其他申请报名") {
        this.list1.unshift(...this.addList);
      }
      let ksbmbh = this.ksbmbh;
      //获取考试报名表单
      getExamForm({ ksbmbh }).then((res) => {
        if (res.status) {

          if(this.isShowYJ){
            res.data = res.data.filter(x=>{return x.txlx != 'shyj' && x.txlx != 'bmsm'})
          }
          this.list1.push(...res.data);

          if (this.examInfo.kmxzfs == "1") {
            if (this.examInfo.sfgzcn) {
              this.list1.unshift(this.addxk[0]);
              this.listDiff();
            } else {
              this.list1.unshift(this.addxk[1]);
              this.getXKList().then((res) => {
                this.list1[0]["data"].push(...res);
                this.listDiff();
              });
            }
          } else {
            if (this.examInfo.sfgzcn) {
              this.addxk[0].zdfl = "2";
              this.list1.push(this.addxk[0]);
            }
            if (this.examInfo.kslxmc != "其他申请报名") {
              this.list1.unshift(...this.adddw);
              this.getZwList().then(() => {
                this.listDiff();
              });
            } else {
              this.listDiff();
            }
          }
        }
      });
      if (!this.form.sfty && !this.form.sfzstj && this.form.sfty !== null) {
        this.getLimit();
      }
    },
    gznxClick() {
      this.$yzImgDialog().show(this.getFileUrl(this.form.gznxzm));
    },
    getForm(e) {
      //   console.log(e);
    },
  },
  mounted() {},
  watch: {
    form: {
      // deep: true,
      handler(form) {
        if (form && this.headFlag) {
          this.headFlag = false;
          if (form.zpdz) {
            this.getFileUrl(form.zpdz, true).then((res) => {
              if (res.status) {
                this.img = res.data;
              }
            });
          }

          this.getFormList();
        }
      },
    },
    examInfo: {
      handler(val) {
        // this.getFormList();
      },
    },

    kxgList: {
      handler(val) {
        this.listDiff();
      },
    },
    errorList: {
      handler() {
        this.listDiff();
      },
    },
  },
};
</script>

<template>
  <div>
    <div class="outerCotent person-form">
      <div class="msgContent fs-xs enrollForm">
        <el-row
          class="d-flex"
          style="line-height: 314px;align-items: stretch;"
        >
          <el-col style="flex: 5; " ref="list1Container">
            <draggable
              class="msgContent flexList fs-xs"
              :list="list2"
              group="people"
              :disabled="true"
            >
              <div
                class="d-flex msgLabel canmove"
                :class="[
                  'w' + element.zdbl,
                  (element.txlx == 'textarea' || element.txlx == 'shyj' || element.txlx == 'bmsm') ? 'msgtextarea' : '',
                ]"
                v-for="element in list2"
                :key="element.sid"
              >
                <div class="msgName">
                  <span style="color: red" v-if="element.sfbt">*</span
                  >{{ element.zdmc }}
                </div>
                <div
                  class="msgVal selectShow flexList"
                  :class="(element.canEdit ? 'canEdit ' : '') + element.zddm"
                >
                  <yzFormItem
                    :innerElement="element"
                    v-model="form"
                    :examInfo="examInfo"
                    @change="getForm"
                    :readOnly="
                      element.canEdit
                        ? false
                        : element.readOnly
                        ? element.readOnly
                        : readOnly
                    "
                    :text="element.tsxx"
                  ></yzFormItem>
<!--                  <div v-show="element.txlx=='file'" style="line-height:19px">{{element.tsxx}}</div>-->

                </div>
              </div>
            </draggable>
          </el-col>
          <el-col class="guding-img" style="flex: 1">
            <img
              :src="
                form.zpdz
                  ? img
                  : require('@/assets/images/person/person-none.png')
              "
              alt=""
              style="width: 120px; height: 160px; margin-top: 10px"
            />
          </el-col>
        </el-row>
        <el-row>
          <draggable
            class="msgContent flexList fs-xs"
            :list="list3"
            group="people"
            :disabled="true"
          >
            <div
              class="d-flex msgLabel canmove"
              :class="[
                'w' + element.zdbl,
                (element.txlx == 'textarea' || element.txlx == 'shyj' || element.txlx == 'bmsm') ? 'msgtextarea' : '',
                element.row,
              ]"
              v-for="element in list3"
              :key="element.sid"
            >
              <div class="msgName">
                <span style="color: red" v-if="element.sfbt">*</span
                >{{ element.zdmc }}
              </div>
              <div
                class="msgVal selectShow flexList"
                :class="(element.canEdit ? 'canEdit ' : '') + element.zddm"
              >
                <yzFormItem
                  :innerElement="element"
                  v-model="form"
                  :examInfo="examInfo"
                  @change="getForm"
                  :readOnly="
                    element.canEdit
                      ? false
                      : element.readOnly
                      ? element.readOnly
                      : readOnly
                  "
                  :text="element.tsxx"
                ></yzFormItem>
<!--                <div v-show="element.txlx=='file'" style="line-height:19px">{{element.tsxx}}</div>-->

              </div>
            </div>
          </draggable>
        </el-row>
        <div
          class="d-flex msgLabel w100 msgContent"
          style="height: 60px"
          v-if="!readOnly || kxgList.length != 0"
        >
          <div class="msgName"></div>
          <div class="msgVal flexList">
            <button
              type="button"
              class="btn btn-info h35 mr-2 sub-btn"
              @click="open"
            >
              保存信息
            </button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>
        报名信息确认提交后无法修改，确认请点击“确认提交”，暂不提交请点击“保存并返回”。<br />
        <span class="text-danger">注意：报名需“确认提交”才算报名成功。</span>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="save">保存并返回</el-button>
        <el-button type="primary" @click="submitFrom">确认提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
