<template>
  <div class="w-100">
    <div
      class="d-flex align-items-center justify-content-between w-100"
      style="position: relative"
    >
      <div v-if="!form.zgxlmc"></div>
      <div v-if="form.zgxlmc">
        {{ form.zgxlmc }}
        <span v-if="form.xlshzt == 0">
          <i
            class="iconfont icon-icon_minus-circled mr-2 text-secondary"
            v-if="form.xlshzt == 0 || form.xlshzt == null"
          ></i
          >{{ "未审核" }}
        </span>
        <span v-if="form.xlshzt == 1">
          <i
            class="iconfont icon-a-ze-checkedCopy2 mr-2 text-success"
          ></i
          >{{ "在线核查已通过" }}
        </span>
        <span v-if="form.xlshzt == 2 && form.xlzm">
          <i
            class="iconfont icon-a-ze-checkedCopy2 mr-2 text-success"
          ></i
          >{{ "已上传学历证明" }}
        </span>
        <span v-if="form.xlshzt == 2 && !form.xlzm">
          <i
            class="iconfont icon-ze-clear mr-2 text-danger"
          ></i
          >{{ "在线核查未通过" }}
        </span>
        <span v-if="form.xlzm">
          <a
            @click="$yzImgDialog().show(getFileUrl(form.xlzm))"
            href="javascript:;"
            >查看证明</a
          >
        </span>
      </div>
      <button class="btn btn-info btn-sm" v-if="!readOnly" v-b-modal.importXL>
        选择学历
      </button>
    </div>
    <!-- 导入学历弹窗 -->
    <b-modal
      v-if="listData && listData.length != 0"
      id="importXL"
      centered
      title="选择学历信息"
      size="lg"
      title-class="font-18"
      hide-footer
    >
      <div class="mb-2" style="margin-top: -10px">
        <div>
          <div>说明：</div>
          <div>1、此学历信息来源用户填写信息。</div>
        </div>
        <div class="border">
          <table
            class="table table-has-bg light-table table-hover table-bordered"
          >
            <thead class="thead-light">
              <tr>
                <th style="width: 5%">序号</th>
                <th>学校名称</th>
                <th style="width: 15%">学习层次</th>
                <th style="width: 30%">学习时间</th>
                <th style="width: 10%">状态</th>
                <th style="width: 10%">附件</th>
                <th style="width: 10%">操作</th>
              </tr>
            </thead>
            <tbody v-if="listData.length != 0">
              <tr v-for="(item, index) in listData" :key="index">
                <th scope="row">{{ index + 1 }}</th>
                <td>
                  {{ item.details.byyxxhdw }}
                </td>
                <td>
                  {{ item.details.xlmc }}
                </td>
                <td>
                  {{ item.details.rxny }} <span class="m-1">至</span
                  >{{ item.details.jsxyny }}
                </td>
                <td>
                  <div
                    v-if="
                      (item.details.xlshzt
                        ? item.details.xlshzt
                        : item.details.xlxtrzzt) == 0
                    "
                  >
                    <i
                      class="iconfont icon-icon_minus-circled mr-2 text-secondary"
                    ></i>
                    <span>{{ "未审核" }}</span>
                  </div>
                  <div
                    v-if="
                      (item.details.xlshzt
                        ? item.details.xlshzt
                        : item.details.xlxtrzzt) == 1
                    "
                  >
                    <i
                      class="iconfont icon-a-ze-checkedCopy2 mr-2 text-success"
                    ></i
                    ><span>{{ "在线核查已通过" }}</span>
                  </div>
                  <div
                    v-if="
                      (item.details.xlshzt
                        ? item.details.xlshzt
                        : item.details.xlxtrzzt) == 2
                    "
                  >
                    <i class="iconfont icon-ze-clear mr-2 text-danger"></i
                    ><span>{{ "在线核查未通过" }}</span>
                  </div>
                </td>
                <td>
                  <span v-if="item.details.xlzm">
                    <a
                      @click="
                        $yzImgDialog().show(getFileUrl(item.details.xlzm))
                      "
                      href="javascript:;"
                      >附件</a
                    >
                  </span>
                  <div
                    v-if="
                      !item.details.xlzm &&
                      (item.details.xlshzt
                        ? item.details.xlshzt
                        : item.details.xlxtrzzt) == 2
                    "
                  >
                    <el-upload
                      class="upload-demo d-flex align-items-center"
                      action=""
                      ref="xlzm"
                      :auto-upload="false"
                      :on-change="
                        (file) => {
                          return upload(file, 'xlzm', index);
                        }
                      "
                      :limit="1"
                      :show-file-list="false"
                      :accept="astrict.xlzm.ext"
                    >
                      <a href="javascript:;">上传学历证明文件</a>
                    </el-upload>
                  </div>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-info h30 mr-2"
                    @click="Import(index)"
                    v-if="
                      (item.details.xlshzt
                        ? item.details.xlshzt
                        : item.details.xlxtrzzt) == 1 ||
                      item.details.xlzm ||
                      examInfo.xlzdsh != 1
                    "
                  >
                    选择
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="text-center" v-if="listData.length == 0">
            无可导入信息
          </div>
        </div>
      </div>
    </b-modal>
    <!-- 导入学历弹窗结束 -->
  </div>
</template>

<script>
import { getUploadLimit } from "@/api/index";
import { unitsChange, uploadFile } from "@/libs/common";
import { getUserEdu, saveXLZM } from "@/api/userInfo.js";
export default {
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    readOnly: {
      default: false,
    },
    dict: {
      type: Array,
      required: false,
    },
    bound: Array,
    form: Object,
    examInfo: Object,
    boundDict: {
      type: Array,
      default: function () {
        return ["value", "name"];
      },
    },
  },

  data() {
    return {
      listData: [],
      xlData: {},
      xlDict: {
        zgxlm: "xlm", //最高学历码
        zgxlmc: "xlmc", //最高学历名称
        // zgxwm: "hdxwmc", //最高学位码
        // zgxwmc: "hdxwm", //最高学位名称
        zhbyxxmc: "byyxxhdw", //最后毕业学校
        zhbyzy: "sxzymc", //最后毕业专业
        zgxlxxfs: "xxfsm", //最高学历学习方式
        zgxlzfh: "xlzsh", //最高学历证书号
        // zgxwxxfs: "",//最高学位学习方式
        // zgxwzsh: "xwzsh", //最高学位证书号
        zhbysj: "jsxyny", //最后毕业时间
        // zhbyxxlb: "",//最后毕业学校类别
        zhbyxxdqm: "gjdqm", //最后毕业学校国家地区码
        zhbyxxdq: "gjdqmc", //最后毕业学校国家地区
        xlshzt: "xlshzt", //学历审核状态
        // xlshsm: "xlshbz", //学历审核状态说明
        zgxlsid: "sid",
        xlzm: "xlzm",
      },
      astrict: {
        xlzm: {},
      }, //文件限制
    };
  },
  methods: {
    getLimit() {
      getUploadLimit({ module: "UserEducation" }).then((res) => {
        if (res.status) {
          this.astrict.xlzm["ext"] =
            "." + res.data.extLimit.replaceAll(",", ",.");
          this.astrict.xlzm["size"] = res.data.sizeLimit;
          let size = unitsChange(res.data.sizeLimit);
          this.astrict.xlzm["text"] =
            "只支持" + res.data.extLimit + "，类型的文件，且不超过" + size;
        }
      });
    },
    upload(file, key, index) {
      let _this = this;
      let fileRule = "";
      file = file.raw;
      if (key == "xlzm") {
        fileRule = "UserEducation";
      }
      uploadFile(file, fileRule, this.astrict[key])
        .then((res) => {
          let resFile = res.data[0];
          saveXLZM({
            sid: _this.listData[index].details.sid,
            xlzm: resFile.fileId,
          }).then((res) => {
            if (res.status) {
              _this.$emit("refresh");
              _this.getEdu();
              _this.$message({ type: "success", message: "上传成功" });
            }
          });
        })
        .catch((result) => {
          _this.$message({ type: "error", message: result.message });
        });
    },

    getEdu() {
      getUserEdu().then((res) => {
        if (res.status) {
          let list = [];
          res.data.forEach((v) => {
            list.push({
              name: v.xlmc,
              value: v.xlm,
              details: v,
            });
          });
          this.listData = list;
        }
      });
    },
    Import(index) {
      this.xlData = this.listData[index];
      this.$bvModal.hide("importXL");
      for (const key in this.xlDict) {
        this.form[key] = this.xlData.details[this.xlDict[key]] || "";
      }
      this.form.xlshzt = this.xlData.details.xlshzt
        ? this.xlData.details.xlshzt
        : this.xlData.details.xlxtrzzt;
    },
    updateData() {
      if (this.listData) {
        let seleted = this.listData.find((item) => {
          return this.form[this.bound[0]] == item[this.boundDict[0]];
        });
        if (seleted) {
          this.xlData = seleted;
          let bound = this.boundDict;
          for (const index in this.bound) {
            if (index < 2) {
              this.form[this.bound[index]] = seleted[bound[index]];
            } else {
              let [k1, v1] = this.bound[index].split(":");
              this.form[k1] = seleted.details[v1];
            }
          }
        }
      }
    },
  },
  watch: {
    dict: {
      deep: true,
      handler(newData) {
        this.listData = newData;
        this.updateData();
      },
    },
    // form: {
    //   deep: true,
    //   handler(form) {
    //     let f = this.filter;
    //     if (this.filter != null) {
    //       this.listData = this.dict.filter((item) => {
    //         return !item.details || item.details[f] == form[f];
    //       });
    //     }
    //     this.$emit("out-filter", this.listData);
    //     this.updateData();
    //   },
    // },
  },
  mounted() {
    this.listData = this.dict;
    this.updateData();
  },
  created() {
    this.getLimit();
  },
};
</script>

<style>
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
</style>
