<template>
  <div class="flexList">
    {{ form[bound] ? "文件已上传" : "" }}
    <a @click="fileOpenClick" href="javascript:;">{{
      form[bound] ? "（查看文件）" : ""
    }}</a>
    <button
      v-if="!readOnly"
      type="button"
      class="btn btn-info h30 float-right" style="height: auto"
      @click="$yzUpload().startUpload('FieldUpload', uploadRes)"
    >
      文件上传
    </button>
    <div class="flexList" style="line-height: 19px;flex: 1">
      <span v-if="!readOnly" class="ml-2 mr-2 text-danger">{{tsxx?tsxx:""}}</span>
      {{ !readOnly ? "("+astrict.text +")": "" }}
    </div>
  </div>
</template>

<script>
import { getUploadLimit } from "@/api/index";
import { checkFile, unitsChange, verifyPhone } from "@/libs/common";

export default {
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    readOnly: {
      default: false,
    },
    bound: String,
    element:Object,
    form: Object,
    boundDict: {
      type: Array,
      default: function () {
        return ["value", "name"];
      },
    },
  },

  data() {
    return {
      astrict:{
        ext:'',
        size:'',
        text:''
      },
    tsxx:""
    };
  },
  methods: {
    fileOpenClick() {
      this.$yzImgDialog().show(this.getFileUrl(this.form[this.bound]));
    },
    uploadRes(e) {
      this.form[this.bound] = e.fileId;
      this.$emit("input", this.form);
      this.$forceUpdate()
    },
    //获取文件限制
    getLimit() {
      getUploadLimit({ module: "FieldUpload" }).then((res) => {
        if (res.status) {
          this.astrict["ext"] =
            "." + res.data.extLimit.replaceAll(",", ",.");
          this.astrict["size"] = res.data.sizeLimit;
          let size = unitsChange(res.data.sizeLimit);
          this.astrict["text"] =
            "只支持" + res.data.extLimit + "类型的文件，且不超过" + size;
        }
      });
    },
  },

  watch: {
    form: {
      deep: true,
      handler(form) {

      },
    },
  },
  mounted() {
    // this.updateData();
    this.getLimit()
    if(this.element){
      this.tsxx=this.element.placeholder
    }
  },
};
</script>

<style scoped></style>
