<template>
  <div class="w-100 h-100 overflow">
    <div class="">
      <div v-if="!readOnly" class="listflex">
        <div class="d-flex align-items-center mt-2 no-gutters">
          <div class="col-2 mr-2">姓名</div>
          <div class="col-2 mr-2">关系</div>
          <div class="col-2 mr-2">工作学习单位</div>
          <div class="col-4 mr-2">职务</div>
          <div class="col-1">删除</div>
        </div>
        <div
          v-for="(item, i) in listData"
          :key="i"
          class="d-flex align-items-center mt-2 no-gutters"
        >
          <div class="col-2 mr-2">
            <input
              v-model="listData[i]['xm']"
              placeholder=""
              @input="change"
              class="form-control h30"
            />
          </div>
          <div class="col-2 mr-2">
            <select
              v-model="listData[i]['gx']"
              @change="change"
              class="form-control form-select w-100"
            >
              <option
                v-for="(item, index) in gxList"
                :key="index"
                :value="item.name"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="col-2 mr-2">
            <input
              v-model="listData[i]['dwmc']"
              placeholder=""
              @input="change"
              class="form-control h30"
            />
          </div>
          <div class="col-4 mr-2">
            <input
              v-model="listData[i]['zw']"
              placeholder=""
              @input="change"
              class="form-control h30"
            />
          </div>
          <div class="col-1">
            <i
              class="iconfont icon-riLine-delete-bin-line"
              @click="deleteJL(i)"
            ></i>
          </div>
        </div>
        <div class="add mt-3">
          <i
            class="iconfont icon-plus-square text-info"
            style="font-size: 30px"
            @click="add"
          ></i>
        </div>
      </div>
      <div v-else>
        <div
          v-for="(item, i) in listData"
          :key="i"
          class="d-flex align-items-center mt-2 no-gutters"
        >
          {{ item.gx + "|" + item.xm + "|" + item.dwmc + "|" + item.zw }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDictionary } from "@/libs/common";
export default {
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    readOnly: {
      default: false,
    },
    bound: String,
    form: Object,
    boundDict: {
      type: Array,
      default: function () {
        return ["value", "name"];
      },
    },
  },

  data() {
    return {
      listData: [],
      gxList: [],
    };
  },
  methods: {
    deleteJL(index) {
      if (this.form[this.bound] || this.listData.length > 1) {
        this.listData.splice(index, 1);
        this.change();
      }
    },
    add() {
      this.listData.push({
        xm: "",
        gx: "",
        dwmc: "",
        zw: "",
      });
    },
    change() {
      let list = [];
      this.listData.forEach((v) => {
        let str = v.gx + "|" + v.xm + "|" + v.dwmc + "|" + v.zw;
        list.push(str);
      });
      this.form[this.bound] = list.join("\r\n");
      this.updateData();
    },
    updateData() {
      let data = this.form[this.bound];
      this.listData = [];
      if (data) {
        let list = data.split("\r\n");
        list.forEach((v, i) => {
          if (v) {
            this.listData.push({
              gx: v.split("|")[0],
              xm: v.split("|")[1],
              dwmc: v.split("|")[2],
              zw: v.split("|")[3],
            });
          }
        });
      }
      if (this.listData.length == 0) {
        this.listData.push({
          xm: "",
          gx: "",
          dwmc: "",
          zw: "",
        });
      }
    },
  },
  watch: {
    form: {
      deep: true,
      handler(form) {
        this.updateData();
      },
    },
  },
  mounted() {
    this.updateData();
  },
  created() {
    getDictionary("dict_gx").then((res) => {
      let data = res.dict_gx
      data = data.filter(v=>{
        return v.value[0] != 0 && v.value[1] != 0
      })
      this.gxList = data;
    });
  },
};
</script>

<style>
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
.listflex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.overflow {
  overflow-y: scroll;
}
</style>
