<template>
  <div class="w-100 h-100 overflow">
    <div class="">
      <div v-if="!readOnly" class="listflex">
        <div class="d-flex align-items-center mt-2 no-gutters">
          <div class="col-2 mr-2">开始时间：</div>
          <div class="col-2 mr-2">结束时间：</div>
          <div class="col-2 mr-2">学校</div>
          <div class="col-2 mr-2">专业</div>
          <div class="col-2 mr-2">培养层次</div>
          <div class="col-1">删除</div>
        </div>
        <div
          v-for="(item, i) in listData"
          :key="i"
          class="d-flex align-items-center mt-2 no-gutters"
        >
          <div class="col-2 mr-2">
            <el-date-picker
              v-model="item['kssj']"
              :clearable="false"
              type="date"
              placeholder="开始时间"
              @input="change"
              value-format="yyyy-MM-dd"
              class="h30 w-100"
            >
            </el-date-picker>
          </div>
          <div class="col-2 mr-2">
            <el-date-picker
              v-model="item['jssj']"
              :class="{ simulateVue_to_date: item.zj }"
              :placeholder="item.zj ? '至今' : '结束时间'"
              :clearable="false"
              type="date"
              @input="change"
              @focus="nowDataIndex = i"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              class="h30 w-100"
            >
            </el-date-picker>
          </div>
          <div class="col-2 mr-2">
            <input
              v-model="item['xxmc']"
              placeholder=""
              @input="change"
              class="form-control h30 w-100"
            />
          </div>
          <div class="col-2 mr-2">
            <input
              v-model="item['zymc']"
              placeholder=""
              @input="change"
              class="form-control h30 w-100"
            />
          </div>
          <div class="col-2 mr-2">
            <select
              v-model="item['pycc']"
              @change="change"
              class="form-control form-select w-100"
            >
              <option
                v-for="(item, index) in xlList"
                :key="index"
                :value="item.name"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="col-1">
            <i
              class="iconfont icon-riLine-delete-bin-line"
              @click="deleteJL(i)"
            ></i>
          </div>
        </div>
        <div class="add mt-3">
          <i
            class="iconfont icon-plus-square text-info"
            style="font-size: 30px"
            @click="add"
          ></i>
        </div>
      </div>
      <div v-else>
        <div
          v-for="(item, i) in listData"
          :key="i"
          class="d-flex align-items-center mt-2 no-gutters"
        >
          {{
            item.kssj +
            "至" +
            (item.jssj?item.jssj: item.zj ? "今" : "") +
            "|" +
            item.xxmc +
            "|" +
            item.zymc +
            "|" +
            item.pycc
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDictionary } from "@/libs/common";
export default {
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    readOnly: {
      default: false,
    },
    bound: String,
    form: Object,
    boundDict: {
      type: Array,
      default: function () {
        return ["value", "name"];
      },
    },
  },

  data() {
    let _this = this;
    return {
      listData: [],
      nowDataIndex: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "至今",
            onClick(picker) {
              picker.$emit("pick", "");
              _this.endTime();
            },
          },
        ],
      },
      xlList: [],
    };
  },
  methods: {
    endTime() {
      this.listData[this.nowDataIndex].zj = true
      this.change();
    },
    deleteJL(index) {
      if (this.form[this.bound] || this.listData.length > 1) {
        this.listData.splice(index, 1);
        this.change();
      }
    },
    add() {
      this.listData.push({
        kssj: "",
        jssj: "",
        xxmc: "",
        zymc: "",
        xlzsh: "",
        pycc: "",
        zj: "",
      });
    },
    change() {
       if (this.nowDataIndex != null && this.listData[this.nowDataIndex].jssj) {
        this.listData[this.nowDataIndex].zj = false;
      }
      let list = [];
      this.listData.forEach((v) => {
        let jssj = v.jssj ? v.jssj : v.zj ? "今" : "";
        let str =
          v.kssj +
          "至" +
          jssj +
          "|" +
          v.xxmc +
          "|" +
          v.zymc +
          "|" +
          v.xlzsh +
          "|" +
          v.pycc;
        list.push(str);
      });
      this.form[this.bound] = list.join("\r\n");
      this.updateData();
    },
    updateData() {
      let data = this.form[this.bound];
      this.listData = [];
      if (data) {
        let list = data.split("\r\n");
        list.forEach((v, i) => {
          if (v) {
            this.listData.push({
              kssj: v.split("|")[0].split("至")[0],
              jssj:
                v.split("|")[0].split("至")[1] == "今"
                  ? ""
                  : v.split("|")[0].split("至")[1],
              xxmc: v.split("|")[1],
              zymc: v.split("|")[2],
              xlzsh: v.split("|")[3],
              pycc: v.split("|")[4],
              zj: v.split("|")[0].split("至")[1] == "今" ? true : false,
            });
          }
        });
      }
      if (this.listData.length == 0) {
        this.listData.push({
          kssj: "",
          jssj: "",
          xxmc: "",
          zymc: "",
          xlzsh: "",
          pycc: "",
          zj: "",
        });
      }
    },
  },
  watch: {
    form: {
      deep: true,
      handler(form) {
        this.updateData();
      },
    },
  },
  mounted() {
    this.updateData();
  },
  created() {
    getDictionary("dict_xl").then((res) => {
      let List = [];
      for (let index = 1; index < 10; index++) {
        let list = res.dict_xl.filter((item) => {
          return item.value[0] == index;
        });

        if (list.length != 0) {
          let options = list.filter((v) => {
            return v.value[1] != 0;
          });
          if (options.length != 0) {
            List.push(...options);
          }
        }
      }
      this.xlList = List;
    });
  },
};
</script>

<style>
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
.listflex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.overflow {
  overflow-y: scroll;
}
</style>
