<template>
  <div class="flexList" style="width: 100%">
    <b-form-checkbox
      switch
      class="switch-check"
      v-model="form[bound]"
       v-if="!readOnly"
       style="display:flex"
    >
    </b-form-checkbox>

    <div v-if="readOnly" class="switchText">{{ form[bound] ? "是" : "否" }}</div>
  </div>
</template>
<script>
import $ from "jquery";

export default {
  model: {
    prop: "form",
    event: "update",
  },
  props: {
    bound: null,
    form: Object,
    readOnly: Boolean,
  },
  methods: {
    
  },
  mounted() {
  },
};
</script>
<style scoped>
.switchText{
    padding: 0 12px;
}
</style>
